import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import aboutBG from "../images/1.0_backgroundheader.jpg"
import legalRound from "../images/legal-round.jpg";

const SiteMapPage = () => {

  return (
    <Layout>
      <SEO title="Neuroblastoma-Info Site Map" description="Quickly find and access any page on neuroblastoma-info.com." />

      <InteriorPageHeader backgroundImage={aboutBG} roundImage={legalRound} alt="Site Map">
        <h1 className="section__heading section__heading--green h1">Site Map</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns">

                <p><Link to="/">Home</Link></p>


                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">About Neuroblastoma & Research</strong></li>
                    <li><Link to="/what-is-neuroblastoma/">What is Neuroblastoma? </Link></li>
                    <li><Link to="/what-is-neuroblastoma/how-is-it-diagnosed/">How is Neuroblastoma Diagnosed? </Link></li>
                    <li><Link to="/what-is-neuroblastoma/clinical-trials/">Treatment and Clinical Trials </Link></li>
                    <li><Link to="/what-is-neuroblastoma/clinical-trial-results/">Understanding Clinical Trials </Link></li>
                    <li><Link to="/what-is-neuroblastoma/research/">Neuroblastoma Research </Link></li>
                  </ul>



                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">Newly Diagnosed</strong></li>
                    <li><Link to="/diagnosis/">Coping with a Neuroblastoma Diagnosis </Link></li>
                    <li><Link to="/diagnosis/understanding-treatment/">Understanding the Treatment Options </Link></li>
                    <li><Link to="/diagnosis/treatment-options/">Preparing for Treatment </Link></li>
                    <li><Link to="/diagnosis/healthcare-team/">Getting to Know Your Treatment Team </Link></li>
                  </ul>



                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">In Treatment</strong></li>
                    <li><Link to="/treatment/non-high-risk-neuroblastoma/">Non&ndash;High-Risk (Low- or Intermediate-Risk Treatment) </Link></li>
                    <li><Link to="/treatment/high-risk-neuroblastoma/">High-Risk Treatment </Link></li>
                  </ul>



                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">Supportive Care</strong></li>
                    <li><Link to="/supportive-care/">Supportive Care Overview </Link></li>
                    <li><Link to="/supportive-care/nausea-vomiting/">Nausea and Vomiting </Link></li>
                    <li><Link to="/supportive-care/constipation-diarrhea/">Constipation and Diarrhea </Link></li>
                    <li><Link to="/supportive-care/diet-nutrition/">Decreased Appetite and Nutrition </Link></li>
                    <li><Link to="/supportive-care/blood-counts/">Blood Counts </Link></li>
                    <li><Link to="/supportive-care/hair-loss/">Hair Loss </Link></li>
                    <li><Link to="/supportive-care/mouth-sores/">Mouth Sores</Link></li>
                    <li><Link to="/supportive-care/pain/">Pain </Link></li>
                  </ul>



                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">Relapsed & Refractory</strong></li>
                    <li><Link to="/relapsed-refractory-neuroblastoma/">What Is Relapsed/Refractory Neuroblastoma? </Link></li>
                  </ul>



                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">After Treatment</strong></li>
                    <li><Link to="/after-treatment/whats-next/">What Happens Next and When</Link></li>
                    <li><Link to="/after-treatment/late-and-long-term-side-effects/">Late and Long Term Side Effects</Link></li>
                  </ul>

                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">Support</strong></li>
                    <li><Link to="/support/mental-health/">Mental Health and the Childhood Cancer Journey </Link></li>
                    <li><Link to="/support/braving-neuroblastoma/">Braving Neuroblastoma </Link></li>
                    <li><Link to="/support/caregiver-support/">Caring for the Caregiver </Link></li>
                  </ul>

                  <ul className="ul--standard lg_mt-4 mt-4">
                    <li><strong className="tc-viridian">Resources</strong></li>
                    <li><Link to="/resources/request-resources/">Request Resources </Link></li>
                    <li><Link to="/resources/doctor-discussion-guide/">Questions to Ask Your Doctor </Link></li>
                    <li><Link to="/resources/downloadable-resources/">Downloadable Resources & Videos </Link></li>
                    <li><Link to="/resources/support-groups/">Neuroblastoma Support and Advocacy Groups </Link></li>
                    <li><Link to="/resources/skivolos-kid-zone/">Skivolo's Kid Zone </Link></li>
                    <li><Link to="/resources/glossary/">Glossary of Terms </Link></li>
                  </ul>


                <p className="lg_mt-4 mt-4">This website is intended for informational purposes and is not intended as treatment advice. It is not meant to replace conversations with your child's healthcare team. You should reach out to them with any questions you have regarding your child's treatment. Your child's healthcare team is your main source of information about your child's care and treatment.</p>

              </div>
            </div>
          </div>
        </div>
      </Section>

    </Layout>
  )
}

export default SiteMapPage
